<template>
<v-dialog v-model="dialog" persistent class="pa-0 ma-0" :overlay-opacity="100" overlay-color="shaza_dark_blue">
  <v-card width="400" class="ma-auto transparent pa-0" flat>
    <v-img :src="allImages.logo_white_wordmark_plain" />
    <v-card-actions>
      <v-spacer />
        <v-btn block color="shaza_yellow" @click.native="launchFederatedSignin">{{buttonLang.signIn.label}}</v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</v-dialog>
</template>

<script>
  import { Auth } from 'aws-amplify'
  export default {
    data(){
      return {
        dialog: true,
        loading: false,
      }
    }
  }
</script>